<template>
  <span v-if="item.type == 'label'">{{ value }}</span>
  <el-input v-else-if="item.type == 'input'" type="text" v-model.trim="value" :style="{ width: width }"
    :disabled="item.disabled" />
  <el-input v-else-if="item.type == 'textarea'" type="textarea" :rows="4" v-model.trim="value" :style="{ width: width }"
    :disabled="item.disabled" />
  <el-input v-else-if="item.type == 'password'" type="password" v-model.trim="value" :style="{ width: width }"
    :disabled="item.disabled" />
  <el-input v-else-if="item.type == 'input-decimal'" v-model="value" v-format.float :style="{ width: width }"
    :disabled="item.disabled" />
  <el-input v-else-if="item.type == 'input-decimal(3)'" v-model="value" v-format.float="3" :style="{ width: width }"
    :disabled="item.disabled" />
  <el-input-number v-else-if="item.type == 'input-int' || item.type == 'input-number'" v-model="value"
    controls-position="right" :precision="item.type == 'input-int' ? 0 : 3"
    :min="item.config && item.config.range ? item.config.range[0] : 0"
    :max="item.config && item.config.range ? item.config.range[1] : 9999999" :style="{ width: width }"
    :disabled="item.disabled" />
  <el-select v-else-if="item.type == 'select' || item.type == 'select-multi'" v-model="value" :disabled="item.disabled"
    :multiple="item.type == 'select-multi'" :collapse-tags="item.type == 'select-multi'" :clearable="!item.required"
    filterable placeholder="请选择" :style="{ width: width }">
    <el-option v-for="(option, optionIndex) in options" :key="optionIndex" :label="option.label" :value="option.value"
      :disabled="option.disabled"></el-option>
  </el-select>
  <select-tree v-else-if="item.type == 'select-tree'" :propName="item.prop" :prop="value" :treeData="itemOptions"
    @handleChangeTreeNode="changeTreeNode" :style="{ width: width }" />
  <el-switch v-else-if="item.type == 'switch'" v-model="value" active-color="#13ce66" inactive-color="#dcdfe6"
    :disabled="item.disabled" />
  <el-switch v-else-if="item.type == 'switch-string'" v-model="value" active-value="1" inactive-value="0"
    active-color="#13ce66" inactive-color="#dcdfe6" :disabled="item.disabled" />
  <el-switch v-else-if="item.type == 'switch-int'" v-model="value" :active-value="1" :inactive-value="0"
    active-color="#13ce66" inactive-color="#dcdfe6" :disabled="item.disabled" />
  <el-cascader v-else-if="item.type == 'cascader'" v-model="value" :options="options" filterable clearable collapse-tags
    :props="{ checkStrictly: item.config.isCheckStrictly, multiple: item.config.isMultiple, }"
    :show-all-levels="item.config.isShowAllLevels" :style="{ width: width }" @change="handleCascaderChange" />
  <el-checkbox v-else-if="item.type == 'checkbox'" v-model="value" :disabled="item.disabled">
    {{ showLabel ? item.label : '' }}
  </el-checkbox>
  <el-checkbox v-else-if="item.type == 'checkbox-string'" v-model="value" true-label="1" false-label="0"
    :disabled="item.disabled">
    {{ showLabel ? item.label : '' }}
  </el-checkbox>
  <el-checkbox v-else-if="item.type == 'checkbox-int'" v-model="value" :true-label="1" :false-label="0"
    :disabled="item.disabled">
    {{ showLabel ? item.label : '' }}
  </el-checkbox>
  <el-checkbox v-else-if="item.type == 'checkbox-inner'" v-model="value"
    :style="{ position: 'relative', left: '-30px', width: '0', }" :disabled="item.disabled" />
  <el-checkbox v-else-if="item.type == 'checkbox-inner-string'" v-model="value" true-label="1" false-label="0"
    :style="{ position: 'relative', left: '-30px', width: '0', }" :disabled="item.disabled" />
  <el-checkbox v-else-if="item.type == 'checkbox-inner-int'" v-model="value" :true-label="1" :false-label="0"
    :style="{ position: 'relative', left: '-30px', width: '0', }" :disabled="item.disabled" />
  <el-radio v-else-if="item.type == 'radio'" v-model="value" :label="item.defaultValue" :disabled="item.disabled">
    {{ showLabel ? item.label : '' }}
  </el-radio>
  <el-date-picker v-else-if="item.type.indexOf('date') > -1" v-model="value" :type="item.type" start-placeholder="开始日期"
    end-placeholder="结束日期" placeholder="选择日期时间" :clearable="false" :style="{ width: width }"
    :disabled="item.disabled" />
  <span v-else-if="item.type == 'img'">
    <img :src="value ? value : noImgUrl" :style="{ width: '80px', height: '80px', border: '1px solid #ededed', }" />
    <el-upload :action="uploadUrl" :headers="headers" accept="image/*" :ref="item.prop" :show-file-list="false"
      :on-success="(response, file, fileList) => uploadSuccess(response, file, fileList, item.prop)"
      :on-error="uploadImgError">
      <el-button size="small" type="primary" :style="{ width: '80px' }">上传</el-button>
    </el-upload>
  </span>
  <ueditor-wrap v-else-if="item.type == 'editor'" v-model="value" :config="{ initialFrameWidth: width }" />
</template>

<script>
import { baseURL, noImgUrl, defaultUploadUrl, successCode } from "@/config";

export default {
  name: "EditItem",
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      require: true,
    },
    itemValue: {
      require: true
    },
    width: {
      type: String,
    },
  },
  data() {
    return {
      noImgUrl,
      headers: {
        Authorization: "Bearer " + this.$store.getters["user/accessToken"],
      },
    };
  },
  computed: {
    value: {
      get() {
        return this.itemValue;
      },
      set(val) {
        if (val == null) {
          return;
        }
        if (this.itemValue != val) {
          this.$emit('update:item-value', val);
        }
      }
    },
    options() {
      let itemOptions = [];
      if (this.item.config.optionJson) {
        try {
          itemOptions = JSON.parse(this.item.config.optionJson);
        } catch (ex) {
          console.log(ex);
          itemOptions = [];
        }
      } else if (this.item.config.options) {
        itemOptions = this.item.config.options;
      }
      return itemOptions;
    },
    uploadUrl() {
      return (
        baseURL + (this.item.config.uploadUrl || defaultUploadUrl)
      );
    },
  },
  methods: {
    changeTreeNode(prop, value) {
      this.$emit('update:item-value', value);
    },
    handleCascaderChange(value) {
      this.$emit('update:item-value', value);
    },
    uploadSuccess(response, file, fileList, prop) {
      if (successCode.includes(response.code)) {
        this.form[prop] = response.data;
      } else {
        this.$baseMessage("上传失败，" + response.msg, "error");
      }
      this.$refs[prop].clearFiles();
    },
    uploadImgError() {
      this.$baseMessage("上传失败", "error");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-inner {
  margin-right: 0;
}
</style>
