<template>
    <el-dialog :visible="visible" :title="title" @close="handleCancel" :close-on-click-modal="false" :width="dialogWidth">
        <el-form :model="formValue" :inline="false" label-position="right" :label-width="labelWidth" label-suffix="：">
            <el-form-item v-for="(item, index) in formItems" :key="index" :label="item.label" :prop="item.prop"
                v-show="!item.config || !item.config.depends || formValue[item.config.depends.prop] == item.config.depends.value">
                <edit-item :width="item.width ? item.width : `calc(100% - 22px - ${labelWidth} - ${tipWidth})`"
                    :item-value.sync="formValue[item.prop]" :item="item" :show-label="false"></edit-item>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">关闭</el-button>
            <el-button type="primary" @click="handleSave">保存</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import EditItem from "@/views/purchase/components/editItem.vue";

export default {
    name: "editSupplier",
    components: {
        EditItem
    },
    mounted() {
        this.getBotQQs();
        this.getMarkets();
    },
    data() {
        return {
            visible: false,
            title: '供应商设置',
            dialogWidth: '400px',
            labelWidth: '120px',
            tipWidth: '60px',
            botQQList: [],
            markets: [],
            formItems: [],
            formValue: {},
        };
    },
    methods: {
        async getBotQQs() {
            let { data } = await submit("/api/stallBotAccount/getList");
            this.botQQList = [];
            data.forEach(item => {
                this.botQQList.push({ label: item.BotName, value: item.BotUid });
            });
        },
        async getMarkets() {
            let { data } = await submit("/api/purchase/supplier/getMarkets");
            this.markets = data;
        },
        init(value, title = '') {
            if (title != '') {
                this.title = title;
            }
            this.formValue = value;
            this.formItems = [
                { type: 'input', label: 'QQ', prop: 'qq', width: '200px' },
                {
                    type: 'select', label: 'QQ关系', prop: 'type', width: '200px',
                    config: { options: [{ label: '好友', value: 'private' }, { label: '群聊', value: 'group' }], isMultiple: false }
                },
                {
                    type: 'select', label: '联系QQ', prop: 'botQQ', width: '200px',
                    config: { options: this.botQQList, isMultiple: false }
                },
                { type: 'switch', label: '选择市场', prop: 'noCustom', },
                {
                    type: 'input', label: '自定义市场', prop: 'market', width: '200px',
                    config: { depends: { prop: 'noCustom', value: false } }
                },
                {
                    type: 'select', label: '所属市场', prop: 'market', width: '200px',
                    config: { options: this.markets, isMultiple: false, depends: { prop: 'noCustom', value: true } }
                }];
            if (this.formValue.market && !this.markets.some(t => t.value == this.formValue.market)) {
                this.formValue.noCustom = false;
            }
            this.visible = true;
        },
        async handleSave() {
            this.$emit("handleChange", this.formValue);
            this.handleCancel();
        },
        handleCancel() {
            this.formValue = {};
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}

.el-form-item__content {
    margin-left: 0px;
}

.div-spec-info {
    padding: 10px 0px 0px 7px;

    .div-check-item {
        margin-top: 5px;
        margin-left: 15px;
        display: flex;

        .el-checkbox {
            margin-right: 10px;
        }

        .div-check-item-color {
            float: left;
            width: 20%;
            overflow: hidden;
        }

        .div-check-item-size {
            float: left;
            width: 80%;
            overflow: hidden;
        }
    }
}
</style>